import { DataStore } from '$lib/firebase/datastore';
import { v4 as uuidv4 } from 'uuid';
import { cyrb53 } from './cyrb53';

const dataStore = new DataStore();

async function createAnonUser(userEmail: string = '', userName: string = '') {
  const identifier = cyrb53(uuidv4());
  const anonymousEmail = `metaweb.fi+${identifier}@gmail.com`;
  if (userEmail != '') {
    const username = userEmail.split('@')[0];
    const email = userEmail;
    const password = uuidv4();
    console.log(username, email, password);
    let anonymousLogIn = await dataStore.createAccount(email, username, password);
    if (anonymousLogIn?.errorMessage) anonymousLogIn = await createAnonUser('', username);
    return anonymousLogIn;
  } else {
    const username = userName || `anonymousUser-${identifier}`;
    const anonymousLogIn = await dataStore.createAccount(anonymousEmail, username, uuidv4());
    return anonymousLogIn;
  }
}

export { createAnonUser };
